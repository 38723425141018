.App {
  text-align: center;
}

.App-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #282c34;
  padding: 10px; /* Reduce padding to make the header smaller */
  color: white;
}

.App-logo {
  display: none;
  width: 100px; /* Adjust the size of the logo */
  height: 100px; /* Ensure it's a square */
  margin: 0px; /* Adjust margin to reduce space around the logo */
}

.App-banner {
  width: 100%;
}

.Banner-image {
  width: 100vw; /* Fullscreen width */
  height: auto;
}

.game-table {
  width: 100%;
  border-collapse: collapse;
  margin: 0px 0;
  font-size: 30px;
  text-align: left;
}

.game-table th, .game-table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
}

.game-table th {
  background-color: #009879;
  color: #ffffff;
  text-align: center;
}

.game-table tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.game-table tr:nth-of-type(odd) {
  background-color: #ffffff;
}

.game-table tr:hover {
  background-color: #f1f1f1;
}
