.game-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 26px;
    text-align: left;
}

.game-table th, .game-table td {
    padding: 12px 15px;
    border: 1px solid #ddd;
}

.game-table th {
    background-color: #009879;
    color: #ffffff;
    text-align: center;
}

.game-table tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.game-table tr:nth-of-type(odd) {
    background-color: #ffffff;
}

.game-table tr:hover {
    background-color: #f1f1f1;
}

.blind {
    font-size: 35px; /* Adjust the size as needed */
    font-weight: bold; /* Makes the text bold */
}